import { Link } from "gatsby"
import * as React from "react"
import * as styles from "./MobileMenuItem.module.css"
import MobileMenuItemList from "./MobileMenuItemList"

// Can either be a dropdown or a link
const MobileMenuItem = ({
  menuItem, // individual menu item pulled from graphql
  isSelected = false, // whether or not the menu item is expanded
  handleMenuItemSelectionFn, // callback that lets parent know when menu item selection is made and handle all side effects
  childLevel, // 0 indexed number that represents how many recursive layers down this menu item is. this var can change what styles or logic is applied
}) => {
  const id = menuItem.id
  const label = menuItem.label
  const url = menuItem.url
  const customCssClasses = menuItem.cssClasses?.join(" ")
  const childMenuItems = menuItem?.childItems?.nodes
  const description = menuItem?.description

  const isChildLevel2CategoryTitle =
    childLevel === 2 && childMenuItems?.length > 0

  const isExternalLink = url?.includes("tel:") || url?.includes("https://")

  return (
    <div className={childLevel === 0 ? styles.MenuItemBorder : ""}>
      {/* Dropdown if menu item has children */}
      {childMenuItems?.length > 0 && (
        // NOTE: We remove link styles from the child level 2 category title since we want to act like a category title, not a dropdown menu item
        <div
          className={`${styles.Dropdown} ${
            isChildLevel2CategoryTitle ? "no-link" : ""
          }`}
          onClick={() => handleMenuItemSelectionFn(id)}
        >
          {/* Label */}
          {/* NOTE: Top level menu item is highlighted green when expanded */}
          {/* NOTE: We lower the font size of the child level 2 category menu item so it looks like a category title, and less like a dropdown option */}
          <div
            className={`${styles.Label} ${
              isSelected && childLevel === 0 ? styles.Highlighted : ""
            } ${isChildLevel2CategoryTitle ? "font-size-12" : ""}`}
          >
            {label}
          </div>

          {/* Dropdown Arrow */}
          {/* NOTE: We don't show the arrow for child level 2 category since it's content is visible by defualt */}
          {childMenuItems?.length && !isChildLevel2CategoryTitle && (
            <div
              className={`${styles.DropdownArrow} ${
                isSelected ? styles.Open : ""
              }`}
            ></div>
          )}
        </div>
      )}

      {/* Link if menu item has no children */}
      {!childMenuItems?.length &&
        (isExternalLink ? (
          <a
            href={url}
            className={`${styles.MenuLink} ${customCssClasses} ${
              childLevel === 0 ? styles.TopLevelMenuLink : ""
            }`}
          >
            {label}
          </a>
        ) : (
          <Link
            to={url}
            className={`${styles.MenuLink} ${customCssClasses} ${
              childLevel === 0 ? styles.TopLevelMenuLink : ""
            }`}
          >
            {label}
          </Link>
        ))}

      {/* Content Hidden/Shown by Dropdown */}
      {/* NOTE: Content for the child level 2 category is visible by default */}
      {
        <div
          className={`${styles.Content} ${
            isSelected || isChildLevel2CategoryTitle ? styles.Visible : ""
          }
          `}
        >
          {/* Some menu items have descriptions */}
          {description && (
            <div className={styles.MenuItemDescription}>{description}</div>
          )}
          <div>
            {childMenuItems?.length > 0 && (
              <MobileMenuItemList
                menuItems={childMenuItems}
                childLevel={childLevel + 1}
              ></MobileMenuItemList>
            )}
          </div>
        </div>
      }
    </div>
  )
}

export default MobileMenuItem
