import { Link } from "gatsby"
import * as React from "react"
import * as styles from "./SubMenu.module.css"

const SubMenu = ({ childItems }) => {
  return (
    <div className={`PacnavDropdown is-1-deep`}>
      <ul>
        {childItems.nodes.map(submenu => {
          const id = submenu.id
          const label = submenu.label
          const url = submenu.url

          return (
            <li key={id}>
              <Link to={url} className={styles.Link}>
                <div>
                  <span>{label}</span>
                </div>
              </Link>
            </li>
          )
        })}
      </ul>
    </div>
  )
}

export default SubMenu
