import { graphql, Link, StaticQuery } from "gatsby"
import * as React from "react"
import Logo from "../../components/Logo/Logo"
import { StoreContext } from "../../store"
import MainMenu from "../Menu/MainMenu"

const LandingPageCta = ({
  viewPortWidth,
  superNavTopLevel,
  headerCtas,
  headerLogo,
}) => {
  const { setMobileSubState, isMobileMenuActive } =
    React.useContext(StoreContext)

  const handleClick = () => {
    if (viewPortWidth < 841) {
      setMobileSubState(false)
      if (typeof window !== "undefined" && window.document) {
        window.document.body.classList.remove("Pacnav--sub-is-active")
      }
    }
  }

  return (
    <StaticQuery
      query={graphql`
        {
          menu: wpMenu(name: { eq: "Landing Page w/CTA Primary" }) {
            menuItems {
              nodes {
                label
                url
                order
              }
            }
          }
        }
      `}
      render={data => {
        const menuItems = data?.menu?.menuItems?.nodes

        return (
          <div className="Wrap">
            <div className="Header-logo">
              <div className="Logo">
                {isMobileMenuActive ? (
                  <a onClick={() => handleClick()}>
                    <Logo logo={headerLogo} />
                  </a>
                ) : (
                  <Link to="/" onClick={() => handleClick()}>
                    <Logo logo={headerLogo} />
                  </Link>
                )}
              </div>
            </div>

            {/* TODO: make this into a component */}
            <div className="Header-wrap">
              <MainMenu topLevel={menuItems} superMenu={superNavTopLevel} />
            </div>

            <div className={`Header-cta`}>
              {headerCtas.map((cta, key) => {
                const link = cta.link
                const label = cta.label
                const type = cta.ctaType

                return (
                  <Link
                    key={key}
                    to={link}
                    className={
                      type
                        ? label.replace(" ", "").toLowerCase()
                        : `Button is-default ${label
                            .replace(" ", "")
                            .toLowerCase()}`
                    }
                  >
                    {label}
                  </Link>
                )
              })}
            </div>
          </div>
        )
      }}
    ></StaticQuery>
  )
}

export default LandingPageCta
