import * as React from "react"
import Logo from "../../components/Logo/Logo"

const OnlyLogo = ({ headerLogo }) => {
  return (
    <div className="Wrap">
      <div className="Header-logo">
        <div className="Logo">
          <Logo logo={headerLogo} />
        </div>
      </div>
    </div>
  )
}

export default OnlyLogo
