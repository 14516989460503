import * as React from "react"
import { StoreContext } from "../../store"
import MenuListItem from "./MenuListItem"
import Hamberger from "./mobile/Hamberger"
import MobileMenu from "./mobile/MobileMenu"

const MainMenu = ({ topLevel = "" }) => {
  const { isMobileMenuActive, viewPortWidth } = React.useContext(StoreContext)

  return (
    <div
      className={
        `Header-nav Pacnav is-mounted ` +
        (viewPortWidth < 841 ? "is-mobile" : "is-desktop") +
        (isMobileMenuActive ? " is-active" : "")
      }
    >
      <ul>
        {viewPortWidth < 841 ? (
          <Hamberger />
        ) : (
          topLevel.map((menuItem, key) => {
            return <MenuListItem key={key} menuItem={menuItem} />
          })
        )}
      </ul>

      {viewPortWidth < 841 && (
        <MobileMenu topLevel={topLevel} viewPortWidth={viewPortWidth} />
      )}
    </div>
  )
}

export default MainMenu
