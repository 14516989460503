import { Link } from "gatsby"
import * as React from "react"

const SuperNav = menu => {
  return (
    <ul id="menu-super-nav-menu" className="primary-super">
      {menu.menu.map(item => {
        const id = item.id
        const label = item.label
        const url = item.url
        const childItems = item.childItems
        const itemSize = item.childItems.nodes.length

        return (
          <li
            key={id}
            className={
              "menu-item menu-item-type-post_type menu-item-object-page " +
              (itemSize > 0 ? `menu-item-has-children` : "")
            }
          >
            {url !== null ? (
              url?.includes("https://") ? (
                <a href={url}>{label}</a>
              ) : (
                <Link to={url}>{label}</Link>
              )
            ) : (
              <a>{label}</a>
            )}

            {itemSize > 0 && (
              <ul className="sub-menu">
                {childItems.nodes.map(item => {
                  const itemId = item.id
                  const url = item.url
                  const label = item.label
                  let flag = false
                  url?.includes("tel:") ? (flag = true) : (flag = false)

                  return (
                    <li
                      key={itemId}
                      className={
                        "menu-item menu-item-type-custom menu-item-object-custom"
                      }
                    >
                      {flag ? (
                        <a
                          href={url}
                          dangerouslySetInnerHTML={{ __html: label }}
                        ></a>
                      ) : (
                        <Link
                          to={url}
                          dangerouslySetInnerHTML={{ __html: label }}
                        ></Link>
                      )}
                    </li>
                  )
                })}
              </ul>
            )}
          </li>
        )
      })}
    </ul>
  )
}

export default SuperNav
