// extracted by mini-css-extract-plugin
export var MenuItemBorder = "MobileMenuItem-module--MenuItemBorder--3ox8q";
export var Dropdown = "MobileMenuItem-module--Dropdown--3nTCQ";
export var MenuLink = "MobileMenuItem-module--MenuLink--3NX4q";
export var TopLevelMenuLink = "MobileMenuItem-module--TopLevelMenuLink--2t21r";
export var MenuItemDescription = "MobileMenuItem-module--MenuItemDescription--35UHp";
export var Label = "MobileMenuItem-module--Label--LJwFE";
export var Highlighted = "MobileMenuItem-module--Highlighted--3ZjtL";
export var DropdownArrow = "MobileMenuItem-module--DropdownArrow--2Bdai";
export var Open = "MobileMenuItem-module--Open--SfSSJ";
export var Content = "MobileMenuItem-module--Content--2kdaS";
export var Visible = "MobileMenuItem-module--Visible--j1HCt";