import { GatsbyImage, getImage } from "gatsby-plugin-image"
import * as React from "react"

const Logo = ({ logo }) => {
  let logoImg = null
  if (logo?.localFile?.childImageSharp?.gatsbyImageData) {
    logoImg = getImage(logo?.localFile?.childImageSharp.gatsbyImageData)
  }
  const logoPublic = logo?.localFile.publicURL
  const logoImgAlt = logo?.altText
  return (
    <>
      {logo.localFile.extension === "svg" && (
        <img src={logoPublic} alt={logoImgAlt} />
      )}

      {logo.localFile.extension !== "svg" && (
        <GatsbyImage image={logoImg} alt={logoImgAlt} />
      )}
    </>
  )
}

export default Logo
