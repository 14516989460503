import { graphql, StaticQuery } from "gatsby"
import PropTypes from "prop-types"
import React, { useCallback, useEffect, useState } from "react"
import DefaultHeader from "./HeaderStyles/DefaultHeader"
import LandingPageCheckrSignup from "./HeaderStyles/LandingPageCheckrSignup"
import LandingPageCta from "./HeaderStyles/LandingPageCta"
import LandingPageLogo from "./HeaderStyles/LandingPageLogo"
import OnlyLogo from "./HeaderStyles/OnlyLogo"

const Header = ({
  viewPortWidth,
  headerStyle = null,
  headerPartnerLogo = null,
}) => {
  const [hasScrolled, setHasScrolled] = useState(false)
  const handleScroll = useCallback(() => {
    if (window.scrollY !== 0) {
      setHasScrolled(true)
    } else {
      setHasScrolled(false)
    }
  })

  useEffect(() => {
    if (typeof window !== "undefined" && window.document) {
      window.addEventListener("scroll", handleScroll)
      return () => {
        window.removeEventListener("scroll", handleScroll)
      }
    }
  }, [])

  return (
    <StaticQuery
      query={graphql`
        {
          primary: wpMenu(name: { eq: "Primary Menu" }) {
            name
            menuItems {
              nodes {
                id
                label
                parentId
                cssClasses

                connectedNode {
                  node {
                    id
                  }
                }

                childItems {
                  nodes {
                    id
                    label
                    url
                    description
                    cssClasses
                    parentId

                    childItems {
                      nodes {
                        id
                        label
                        url
                        cssClasses

                        childItems {
                          nodes {
                            id
                            label
                            url
                            cssClasses
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }

          superNav: wpMenu(name: { eq: "Super Nav Menu" }) {
            id
            name
            menuItems {
              nodes {
                id
                label
                url
                parentId
                cssClasses

                childItems {
                  nodes {
                    id
                    label
                    url
                    cssClasses
                  }
                }
              }
            }
          }

          wp {
            overviewHeader {
              overviewHeader {
                headerCtas {
                  ... on WpOverviewHeader_Overviewheader_HeaderCtas_CallToAction {
                    ctaType
                    label
                    link
                  }
                }
                headerLogo {
                  altText
                  sourceUrl
                  uri
                  title
                  localFile {
                    extension
                    publicURL
                    childImageSharp {
                      gatsbyImageData(
                        formats: AUTO
                        layout: FULL_WIDTH
                        placeholder: BLURRED
                      )
                    }
                  }
                  mediaDetails {
                    height
                    width
                  }
                }
              }
            }
          }
        }
      `}
      render={data => {
        // parent level menu items
        const topLevel = data.primary.menuItems.nodes.filter(
          menuItem => menuItem.parentId === null
        )

        // super nav at the top
        const superNav = data.superNav
        const superNavTopLevel = superNav.menuItems.nodes.filter(
          item => item.parentId === null
        )

        const headerCtas = data.wp.overviewHeader.overviewHeader.headerCtas

        const headerLogo = data.wp.overviewHeader.overviewHeader.headerLogo

        let headerClass = ""

        switch (headerStyle) {
          case "only-logo":
            headerClass = "HeaderOnlyLogo"
            break
          case "landing-page":
            headerClass = "HeaderLandingPage"
            break
          case "landing-page-logo":
            headerClass = "HeaderWithLogo"
            break
          case "landing-page-cta":
            headerClass = "HeaderLandingPage HeaderLandingPage-short"
            break
          case "checkr-signup-cta":
            headerClass =
              "HeaderLandingPage HeaderLandingPage-short checkr-signup"
            break
        }

        return (
          <header
            className={`Header ${headerClass} ${
              hasScrolled ? "is-scrolling" : ""
            }`}
          >
            {(headerStyle === "only-logo" && (
              <OnlyLogo headerLogo={headerLogo} />
            )) ||
              ((headerStyle === "landing-page-logo" ||
                headerStyle === "landing-page") && (
                <LandingPageLogo
                  headerPartnerLogo={headerPartnerLogo}
                  viewPortWidth={viewPortWidth}
                  superNavTopLevel={superNavTopLevel}
                  headerLogo={headerLogo}
                />
              )) ||
              (headerStyle === "landing-page-cta" && (
                <LandingPageCta
                  viewPortWidth={viewPortWidth}
                  superNavTopLevel={superNavTopLevel}
                  topLevel={topLevel}
                  headerCtas={headerCtas}
                  headerLogo={headerLogo}
                />
              )) ||
              (headerStyle === "checkr-signup-cta" && (
                <LandingPageCheckrSignup headerLogo={headerLogo} />
              )) || (
                <DefaultHeader
                  viewPortWidth={viewPortWidth}
                  superNavTopLevel={superNavTopLevel}
                  topLevel={topLevel}
                  headerCtas={headerCtas}
                  headerLogo={headerLogo}
                />
              )}
          </header>
        )
      }}
    ></StaticQuery>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
