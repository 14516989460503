import { Link } from "gatsby"
import * as React from "react"
import * as styles from "./NestedMenu.module.css"

const NestedMenu = ({ childItems }) => {
  if (typeof window !== "undefined" && window.document) {
    Array.from(
      window.document.querySelectorAll(".Pacnav .Pacnav-sub-full-width")
    ).forEach(element => {
      let container = window.document.querySelector(".Header .Wrap")
      let containerPosition = container.getBoundingClientRect()
      let elementPosition = element.getBoundingClientRect()
      let pacnavSub = element.querySelectorAll(".PacnavDropdown")[0]

      if ("undefined" !== typeof pacnavSub) {
        let elementLeft = elementPosition.left - containerPosition.left
        let elementWidth = containerPosition.right - containerPosition.left

        pacnavSub.setAttribute(
          "style",
          `left: -${elementLeft}px; width: ${elementWidth}px;`
        )
      }
    })
  }

  return (
    <>
      {childItems !== null && typeof childItems !== "undefined" && (
        <div className={`PacnavDropdown`}>
          <ul>
            {childItems?.nodes.map(submenu => {
              const id = submenu.id
              const label = submenu.label
              const submenuChild = submenu.childItems
              const desc = submenu.description
              const cssClasses = submenu.cssClasses?.join(" ")

              return (
                <li className={`has-children ${cssClasses}`} key={id}>
                  <div
                    className={
                      !!submenuChild?.nodes?.length
                        ? styles.MenuLinkWithDescription
                        : ""
                    }
                  >
                    <div>
                      <span>{label}</span>
                    </div>

                    {desc && <p>{desc}</p>}
                  </div>

                  <ul>
                    {submenuChild &&
                      submenuChild?.nodes.map(child => {
                        const childKey = child.id
                        const childLabel = child.label
                        const childUrl = child.url
                        const cssClasses = child.cssClasses.join(" ")

                        return !!child.childItems?.nodes?.length ? (
                          <span
                            key={`sub-category ${childLabel}`}
                            className="half-col"
                          >
                            {/* Subcategory Title */}
                            <li className="no-link" key={childKey}>
                              <div
                                className="no-link"
                                style={{ paddingTop: 0 }}
                              >
                                <span className="no-link">{childLabel}</span>
                              </div>
                            </li>

                            {/* Subcategory Links */}
                            <ul>
                              {child.childItems.nodes.map(subChild => {
                                const subChildClasses =
                                  subChild.cssClasses.join(" ")
                                return (
                                  <li key={subChild.id}>
                                    <Link to={subChild.url}>
                                      <div>
                                        <span className={subChildClasses}>
                                          {subChild.label}
                                        </span>
                                      </div>
                                    </Link>
                                  </li>
                                )
                              })}
                            </ul>
                          </span>
                        ) : (
                          <li key={childKey}>
                            <Link to={childUrl}>
                              <div>
                                <span className={cssClasses}>{childLabel}</span>
                              </div>
                            </Link>
                          </li>
                        )
                      })}
                  </ul>
                </li>
              )
            })}
          </ul>
        </div>
      )}
    </>
  )
}

export default NestedMenu
