import { Link } from "gatsby"
import * as React from "react"
import Logo from "../../components/Logo/Logo"
import { StoreContext } from "../../store"
import MainMenu from "../Menu/MainMenu"
import SuperNav from "../Menu/SuperNav"

const DefaultHeader = ({
  viewPortWidth,
  superNavTopLevel,
  topLevel,
  headerCtas,
  headerLogo,
}) => {
  const { isMobileMenuActive } = React.useContext(StoreContext)

  return (
    <div className="Wrap">
      <div className="Header-super">
        <SuperNav menu={superNavTopLevel} />
      </div>

      <div className="Header-logo">
        <div className="Logo">
          {isMobileMenuActive ? (
            <a>
              <Logo logo={headerLogo} />
            </a>
          ) : (
            <Link to="/">
              <Logo logo={headerLogo} />
            </Link>
          )}
        </div>
      </div>

      {/* TODO: make this into a component */}
      <div className="Header-wrap">
        <MainMenu topLevel={topLevel} superMenu={superNavTopLevel} />
      </div>

      <div className="Header-cta">
        {headerCtas.map((cta, index) => {
          const link = cta.link
          const label = cta.label
          const type = cta.ctaType

          return (
            <Link
              key={`cta-${index}`}
              to={link}
              className={
                type
                  ? label.replace(" ", "").toLowerCase()
                  : `Button ${index === 0 ? "is-default" : "is-outline"} ${label
                      .replace(" ", "")
                      .toLowerCase()}`
              }
            >
              {label}
            </Link>
          )
        })}
      </div>
    </div>
  )
}

export default DefaultHeader
