/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import PropTypes from "prop-types"
import * as React from "react"
import { StoreContext } from "../store"
import getWindowDimensions from "../utilities/windowDimensions"

const Layout = ({ children }) => {
  const { setViewPortWidth } = React.useContext(StoreContext)

  const [windowDimensions, setWindowDimensions] = React.useState(
    getWindowDimensions()
  )

  const { isMobileMenuActive, setMobileMenuState, setMobileSubState } =
    React.useContext(StoreContext)

  React.useEffect(() => {
    const handleResize = () => {
      setWindowDimensions(getWindowDimensions())
    }

    setViewPortWidth(windowDimensions.width)

    window.addEventListener("resize", handleResize)
    return () => window.removeEventListener("resize", handleResize)
  }, [windowDimensions, setViewPortWidth])

  // Close any open mobile menus on load
  React.useEffect(() => {
    setMobileSubState(false)
    if (typeof window !== "undefined" && window.document) {
      window.document.body.classList.remove("Pacnav--is-active")
      window.document.body.classList.remove("Pacnav--sub-is-active")
    }
    if (isMobileMenuActive) {
      setMobileMenuState()
    }
  }, [])

  // Store any UTM codes in url to pass back with Marketo form submissions
  React.useEffect(() => {
    const queryParams = new URL(document.location).searchParams

    // Landing Page
    if (!sessionStorage.getItem("landing_page")) {
      sessionStorage.setItem("landing_page", document.location)
    }

    // UTM
    if (queryParams.get("utm_source"))
      sessionStorage.setItem("utm_source", queryParams.get("utm_source"))
    if (queryParams.get("utm_medium"))
      sessionStorage.setItem("utm_medium", queryParams.get("utm_medium"))
    if (queryParams.get("utm_campaign"))
      sessionStorage.setItem("utm_campaign", queryParams.get("utm_campaign"))
    if (queryParams.get("utm_id"))
      sessionStorage.setItem("utm_id", queryParams.get("utm_id"))
    if (queryParams.get("utm_term"))
      sessionStorage.setItem("utm_term", queryParams.get("utm_term"))
    if (queryParams.get("utm_content"))
      sessionStorage.setItem("utm_content", queryParams.get("utm_content"))

    // Ad Platform Click Ids
    if (queryParams.get("msclkid"))
      sessionStorage.setItem("msclkid", queryParams.get("msclkid")) // bing
    if (queryParams.get("fbclid"))
      sessionStorage.setItem("fbclid", queryParams.get("fbclid")) // facebook
    if (queryParams.get("li_fat_id"))
      sessionStorage.setItem("li_fat_id", queryParams.get("li_fat_id")) // linkedin
    if (queryParams.get("gclid"))
      sessionStorage.setItem("gclid", queryParams.get("gclid")) // google
  }, [])

  return (
    <div
      style={{
        margin: `0 auto`,
        padding: `0`,
      }}
    >
      <main
        style={{
          paddingTop: `0`,
        }}
      >
        {children}
      </main>
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
