import { graphql, Link, StaticQuery } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import * as React from "react"
import Logo from "../../components/Logo/Logo"
import { StoreContext } from "../../store"
import MainMenu from "../Menu/MainMenu"

const LandingPageLogo = ({
  headerPartnerLogo,
  viewPortWidth,
  superNavTopLevel,
  headerLogo,
}) => {
  const { setMobileSubState, isMobileMenuActive } =
    React.useContext(StoreContext)

  const handleClick = () => {
    if (viewPortWidth < 841) {
      setMobileSubState(false)
      if (typeof window !== "undefined" && window.document) {
        window.document.body.classList.remove("Pacnav--sub-is-active")
      }
    }
  }

  let logoImg = ""
  let logoAlt = ""
  let ext = headerPartnerLogo?.localFile?.extension

  if (headerPartnerLogo?.localFile?.childImageSharp?.gatsbyImageData) {
    logoImg = getImage(headerPartnerLogo.localFile)
    logoAlt = headerPartnerLogo.altText
  }

  if (ext === "svg") {
    logoImg = headerPartnerLogo.localFile.publicURL
    logoAlt = headerPartnerLogo.altText
  }

  return (
    <StaticQuery
      query={graphql`
        {
          menu: wpMenu(name: { eq: "Landing Page Primary" }) {
            menuItems {
              nodes {
                label
                url
                order
              }
            }
          }
        }
      `}
      render={data => {
        const menuItems = data?.menu?.menuItems?.nodes

        return (
          <div className="Wrap">
            <div className="Header-logo">
              <div className="Logo">
                {isMobileMenuActive ? (
                  <a onClick={() => handleClick()}>
                    <Logo logo={headerLogo} />
                  </a>
                ) : (
                  <Link to="/" onClick={() => handleClick()}>
                    <Logo logo={headerLogo} />
                  </Link>
                )}
              </div>
              {headerPartnerLogo && (
                <div className="LogoPartner">
                  {ext === "svg" ? (
                    <img src={logoImg} alt={logoAlt} />
                  ) : (
                    <GatsbyImage image={logoImg} alt={logoAlt} />
                  )}
                </div>
              )}
            </div>

            {/* TODO: make this into a component */}
            <div className="Header-wrap">
              <MainMenu topLevel={menuItems} superMenu={superNavTopLevel} />
            </div>
          </div>
        )
      }}
    ></StaticQuery>
  )
}

export default LandingPageLogo
