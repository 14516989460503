import { Link } from "gatsby"
import * as React from "react"
import NestedMenu from "./NestedMenu"
import SubMenu from "./SubMenu"

// Top level menu items are not hidden behind the submenu window
const MenuListItem = ({ menuItem }) => {
  const id = menuItem.id
  const label = menuItem.label
  const url = menuItem.url
  const customCssClass = menuItem.cssClasses
  const childItems = menuItem?.childItems
  const childItemSize = childItems?.nodes?.length

  let isExternalLink = false
  url?.includes("tel:") || url?.includes("https://")
    ? (isExternalLink = true)
    : (isExternalLink = false)

  const [isHover, setIsHover] = React.useState(false)

  const addHoverState = () => {
    setIsHover(true)
  }

  const removeHoverState = () => {
    setIsHover(false)
  }

  return (
    <>
      {!customCssClass?.includes("Pacnav--remove-mobile") && (
        <li
          key={id}
          className={
            (childItemSize > 0 ? " has-children " : "") +
            (isHover ? `has-hover ` : "") +
            (childItemSize > 2 ? "" : " Pacnav-sub-full-width ") +
            (customCssClass?.includes("Pacnav--only-default-header")
              ? "Pacnav--only-default-header"
              : "")
          }
          onMouseEnter={() => addHoverState()}
          onMouseLeave={() => removeHoverState()}
          role="presentation"
        >
          {
            // check to find if this is the top level item with no child
            childItemSize <= 0 ||
            childItemSize === null ||
            childItemSize === undefined ? (
              // check to see if this is special case links like tel:
              isExternalLink ? (
                <a href={url}>
                  <div>
                    <span>{label}</span>
                  </div>
                </a>
              ) : (
                <Link to={url}>
                  <div>
                    <span>{label}</span>
                  </div>
                </Link>
              )
            ) : (
              <div>
                <div>
                  <span>{label}</span>
                </div>
              </div>
            )
          }

          {childItemSize > 2 ? (
            <SubMenu childItems={childItems} />
          ) : (
            <NestedMenu childItems={childItems} />
          )}
        </li>
      )}
    </>
  )
}

export default MenuListItem
