import * as React from "react"
import { useState } from "react"
import MobileMenuItem from "./MobileMenuItem"

const MobileMenuItemList = ({
  menuItems,
  childLevel, // 0 indexed number that represents how many recursive layers down this menu item list is
}) => {
  const [selectedMenuItemId, setSelectedMenuItem] = useState(null)

  // Only one dropdown item can be open at a time
  const handleMenuItemSelection = newMenuItemId => {
    // Different dropdown was clicked, so open it
    if (newMenuItemId !== selectedMenuItemId) {
      setSelectedMenuItem(newMenuItemId)
    }
    // Open dropdown was clicked again, so close it
    else {
      setSelectedMenuItem(null)
    }
  }

  return (
    <>
      {menuItems.map((menuItem, index) => {
        return (
          <MobileMenuItem
            key={`${menuItem.label}-${index}`}
            menuItem={menuItem}
            isSelected={menuItem.id === selectedMenuItemId}
            handleMenuItemSelectionFn={handleMenuItemSelection}
            childLevel={childLevel}
          ></MobileMenuItem>
        )
      })}
    </>
  )
}

export default MobileMenuItemList
